import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject()
export class Index {

    constructor(private readonly router: Router) {

    }
}
