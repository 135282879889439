import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';

@autoinject()
export class Index {
    private router: Router;

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'home' },
            { route: 'home', name: 'home', moduleId: PLATFORM.moduleName('./home/index'), title: 'Home', nav: false },
            { route: 'rounds', name: 'rounds', moduleId: PLATFORM.moduleName('./rounds/index'), title: 'Rounds', nav: true, settings: { image: 'medicine-cart.jpg' } },
            { route: 'stock', name: 'stock', moduleId: PLATFORM.moduleName('./stock/index'), title: 'Stock', nav: true },
            { route: 'orders', name: 'orders', moduleId: PLATFORM.moduleName('./orders/index'), title: 'Orders', nav: true },
            { route: 'changes', name: 'changes', moduleId: PLATFORM.moduleName('./changes/index'), title: 'Changes', nav: true },
            { route: 'info', name: 'info', moduleId: PLATFORM.moduleName('./info/index'), title: 'Info', nav: true },
            { route: 'transfers', name: 'transfers', moduleId: PLATFORM.moduleName('./transfers/index'), title: 'Transfers', nav: true },
        ]);
        this.router = router;
    }
}
