import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { ToastrService } from 'services/toastr-service';

@autoinject()
export class App {

    private router: Router;

    constructor(private readonly toastr: ToastrService) {
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'home' },
            { route: 'home', name: 'home', moduleId: PLATFORM.moduleName('./routes/home/index'), title: 'Home', nav: false },
            { route: 'patients', name: 'patients', moduleId: PLATFORM.moduleName('./routes/patients/index'), title: 'Residents', nav: true },
            { route: 'medication', name: 'medication', moduleId: PLATFORM.moduleName('./routes/medication/index'), title: 'Medication', nav: true },
            // { route: 'carehomes', name: 'carehomes', moduleId: PLATFORM.moduleName('./routes/carehomes/index'), title: 'Care Homes', nav: true },
            { route: 'pharmacies', name: 'pharmacies', moduleId: PLATFORM.moduleName('./routes/pharmacies/index'), title: 'Pharmacies', nav: true },
        ]);
        this.router = router;
    }

    public attached() {
        console.log('Carehome plugin loaded');
    }
}
